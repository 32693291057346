import { onBeforeUnmount, onMounted } from "vue";
import useHeaderTip from "@/hooks/Reporting/useHeaderTip";
import "@grapecity/spread-sheets/styles/gc.spread.sheets.excel2016colorful.css";
import GC from "@grapecity/spread-sheets";
import {
  controllingReportProgram,
  ReportProgramResponse,
  HeaderProps,
} from "@/API/report";
import useExportExcel from "@/hooks/Reporting/useExportExcel";
import store from "@/store";
import type { QueryParamsControllerProps } from "@/views/Reporting/types";

interface ExtExportColumnsSetting {
  bu: boolean | string[];
  year: boolean | string;
  q: boolean | string;
}

const columnSetting = {
  BU: {
    width: 80,
    align: "center",
    visible: false,
  },
  Year: {
    width: 60,
    align: "center",
    visible: false,
  },
  "Q/M": {
    width: 100,
    align: "center",
    visible: false,
  },
  "Program Code": {
    width: 130,
    align: "left",
    visible: true,
  },
  "Program Name": {
    width: "*",
    align: "left",
    visible: true,
  },
  Make: {
    width: 60,
    align: "center",
    visible: true,
  },
  "Type Class": {
    width: 80,
    align: "center",
    visible: false,
  },
  Model: {
    width: 60,
    align: "center",
    visible: false,
  },
  "Plan-Estimated Volume": {
    width: 120,
    align: "right",
    visible: true,
  },
  "Plan-Budget Estimation": {
    width: 120,
    align: "right",
    visible: true,
  },
  "Plan-Fleet Volume": {
    width: 120,
    align: "right",
    visible: true,
  },
  "Actual-Updated Budget": {
    width: 120,
    align: "right",
    visible: true,
  },
  "Actual-AVG.SI": {
    width: 80,
    align: "right",
    visible: true,
  },
  "Actual-%AVG.SI": {
    width: 80,
    align: "right",
    visible: true,
  },
  "Sales Active Status": {
    width: 100,
    align: "center",
    visible: true,
  },
  "Controlling Active Status": {
    width: 100,
    align: "center",
    visible: true,
  },
  "Program Status": {
    width: 100,
    align: "center",
    visible: true,
  },
};

const useSpreadTableController = (queryParams: QueryParamsControllerProps) => {
  let workbook: GC.Spread.Sheets.Workbook;

  // 筛选
  const setFilter = (
    sheet: GC.Spread.Sheets.Worksheet,
    index: number,
    len: number
  ) => {
    const range = new GC.Spread.Sheets.Range(-1, index, -1, len);
    const rowFilter = new GC.Spread.Sheets.Filter.HideRowFilter(range);
    sheet.rowFilter(rowFilter);
    rowFilter.filterDialogVisibleInfo({
      sortByColor: false,
    });
  };

  /**
   * 获取导出Excel附加列配置（根据筛选条件添加对应列）
   */
  const getExtExportColumnsSetting = (
    queryParams: QueryParamsControllerProps
  ) => {
    const extExportColumnsSetting: ExtExportColumnsSetting = {
      bu: false,
      year: false,
      q: false,
    };
    if (queryParams.buList) {
      extExportColumnsSetting.bu = queryParams.buList;
    }
    if (queryParams.year !== "") {
      extExportColumnsSetting.year = queryParams.year;
    }
    if (queryParams.monthList.length) {
      extExportColumnsSetting.q = queryParams.monthList.join("/");
    }

    return extExportColumnsSetting;
  };

  /**
   * 获取导出Excel附加列显示的列头
   *
   * @param extExportColumnsSetting
   * @returns
   */
  const getExtExportColumns = (
    extExportColumnsSetting: ExtExportColumnsSetting
  ) => {
    const columns = [];
    if (extExportColumnsSetting.bu) {
      columns.push("BU");
    }
    if (extExportColumnsSetting.year) {
      columns.push("Year");
    }
    if (extExportColumnsSetting.q) {
      columns.push("Q/M");
    }
    return columns;
  };

  // 头部
  const initHeader = (
    sheet: GC.Spread.Sheets.Worksheet,
    extExportColumnsSetting: ExtExportColumnsSetting,
    bu: string[] | undefined
  ) => {
    //挂起
    sheet.suspendPaint();
    const extExportColumns = getExtExportColumns(extExportColumnsSetting).map(
      (item) => {
        return { name: item };
      }
    );
    console.log("bu", bu);
    const headerArr: HeaderProps[][] = [
      [
        ...extExportColumns,
        { name: "Program Code" },
        { name: "Program Name" },
        { name: "Make" },
        { name: "Type Class" },
        { name: "Model" },
        { name: "Plan" },
        { name: "Plan" },
        ...(bu && bu.includes("Fleet") ? [{ name: "Plan" }] : []),
        { name: "Actual" },
        { name: "Actual" },
        { name: "Actual" },
        { name: "Sales Active Status" },
        { name: "Controlling Active Status" },
        { name: "Program Status" },
      ],
      [
        ...extExportColumns,
        { name: "Program Code" },
        { name: "Program Name" },
        { name: "Make" },
        { name: "Type Class" },
        { name: "Model" },
        { name: "Estimated Volume" },
        { name: "Budget Estimation" },
        ...(bu && bu.includes("Fleet") ? [{ name: "Fleet Volume" }] : []),
        {
          name: "Updated Budget",
          formal: "Updated Budget = AVG SI*Kufri",
        },
        {
          name: "AVG.SI",
          formal: "Avg.SI = Updated Budget / Kufri",
        },
        {
          name: "%AVG.SI",
          formal: "AVG.SI% = Updated Budget / Pipeline Override",
        },
        { name: "Sales Active Status" },
        { name: "Controlling Active Status" },
        { name: "Program Status" },
      ],
    ];
    console.log("headerArr", headerArr);
    // 设置表头为两行
    sheet.setRowCount(2, GC.Spread.Sheets.SheetArea.colHeader);
    for (let row = 0; row < headerArr.length; row++) {
      for (let col = 0; col < headerArr[row].length; col++) {
        // 给表头单元格赋值
        sheet.setValue(
          row,
          col,
          headerArr[row][col].name,
          GC.Spread.Sheets.SheetArea.colHeader
        );
        // 设置表头单元格的背景色、字体
        sheet
          .getCell(row, col, GC.Spread.Sheets.SheetArea.colHeader)
          .backColor("#bfbfbf")
          .font("700 15px Calibri");
        // // 设置行高
        sheet.setRowHeight(row, 30, GC.Spread.Sheets.SheetArea.colHeader);
      }
    }

    let preColumnName = "";
    let preColumnIndex = -1;
    for (let col = 0; col < headerArr[0].length; col++) {
      const columnName =
        headerArr[0][col].name === headerArr[1][col].name
          ? headerArr[1][col].name
          : `${headerArr[0][col].name}-${headerArr[1][col].name}`;
      // 设置列宽
      sheet.setColumnWidth(col, columnSetting[columnName].width);
      sheet.setColumnVisible(col, columnSetting[columnName].visible);
      // 合并单元格
      if (headerArr[0][col].name === headerArr[1][col].name) {
        sheet.addSpan(0, col, 2, 1, GC.Spread.Sheets.SheetArea.colHeader);
      }
      if (headerArr[0][col].name !== preColumnName) {
        if (col > preColumnIndex + 1) {
          sheet.addSpan(
            0,
            preColumnIndex,
            1,
            col - preColumnIndex,
            GC.Spread.Sheets.SheetArea.colHeader
          );
        }
        preColumnName = headerArr[0][col].name;
        preColumnIndex = col;
      }

      sheet
        .getCell(-1, col)
        .hAlign(
          GC.Spread.Sheets.HorizontalAlign[
            columnSetting[columnName].align as "left" | "center" | "right"
          ]
        );
    }
    // 合并单元格
    if (preColumnIndex !== headerArr[0].length - 1) {
      sheet.addSpan(
        0,
        preColumnIndex,
        1,
        headerArr[0].length - preColumnIndex,
        GC.Spread.Sheets.SheetArea.colHeader
      );
    }

    // 冻结，固定前面4列
    sheet.frozenColumnCount(extExportColumns.length + 5);
    sheet.setColumnCount(
      headerArr[0].length,
      GC.Spread.Sheets.SheetArea.viewport
    );
    // 固定最后一列
    sheet.frozenTrailingColumnCount(3, false);
    // 筛选
    setFilter(sheet, extExportColumns.length, 5);

    // 设置headerTip
    useHeaderTip(sheet, headerArr, "#ssHost", 150);
    sheet.resumePaint();
  };
  // Spread初始化设置
  const initSpread = (
    spread: GC.Spread.Sheets.Workbook,
    sheet: GC.Spread.Sheets.Worksheet
  ) => {
    sheet.suspendPaint();

    // sheet是否显示
    spread.options.tabNavigationVisible = false;
    spread.options.tabStripVisible = false;

    // 隐藏行头
    sheet.options.rowHeaderVisible = false;

    // 要设置允保护，不允许的才能生效, 不可编辑
    // sheet.options.isProtected = true;
    sheet.defaults.rowHeight = 30;

    const style = sheet.getDefaultStyle();
    style.vAlign = GC.Spread.Sheets.VerticalAlign.center;
    style.hAlign = GC.Spread.Sheets.HorizontalAlign.center;

    // 设置背景色
    sheet.getCell(0, -1).backColor("#D4E6F1");
    spread.resumePaint();
  };

  const bindData = (
    sheet: GC.Spread.Sheets.Worksheet,
    data: string[][],
    extExportColumnsSetting: ExtExportColumnsSetting,
    bu: string[] | undefined
  ) => {
    // 设置行数
    sheet.setRowCount(data.length, GC.Spread.Sheets.SheetArea.viewport);

    const extExportColumnsData = [];

    if (extExportColumnsSetting.bu) {
      extExportColumnsData.push(extExportColumnsSetting.bu);
    }
    if (extExportColumnsSetting.year) {
      extExportColumnsData.push(extExportColumnsSetting.year);
    }
    if (extExportColumnsSetting.q) {
      extExportColumnsData.push(extExportColumnsSetting.q);
    }

    for (let row = 0; row < data.length; row++) {
      data[row].unshift(...(extExportColumnsData as string[]));
    }

    const statusColumnIndex =
      8 + extExportColumnsData.length + (bu && bu.includes("Fleet") ? 1 : 0);

    for (let row = 0; row < data.length; row++) {
      for (let col = 0; col < data[row].length; col++) {
        // 给单元格赋值
        sheet.setValue(
          row,
          col,
          data[row][col],
          GC.Spread.Sheets.SheetArea.viewport
        );
        if (
          (col === statusColumnIndex || col === statusColumnIndex + 1) &&
          data[row][col] === "Inactive"
        ) {
          sheet.getCell(row, col).foreColor("#ff0000");
        }
      }
    }
  };

  const init = (
    queryParams: QueryParamsControllerProps,
    resData: ReportProgramResponse
  ) => {
    workbook?.destroy();
    const spread = new GC.Spread.Sheets.Workbook(
      document.getElementById("ssHost") as HTMLElement
    );
    workbook = spread;
    const sheet = spread.getActiveSheet();
    const extExportColumnsSetting = getExtExportColumnsSetting(queryParams);

    initHeader(sheet, extExportColumnsSetting, queryParams.buList);
    initSpread(spread, sheet);
    bindData(
      sheet,
      resData.tableData,
      extExportColumnsSetting,
      queryParams.buList
    );
  };
  const getFetch = (queryParams: any) => {
    store.commit("updateSpinning", true);
    controllingReportProgram({ params: queryParams })
      .then((res: ReportProgramResponse) => {
        init(queryParams, res);
        store.commit("updateSpinning", false);
      })
      .catch(() => {
        store.commit("updateSpinning", false);
      });
  };

  const handlerDownload = () => {
    const json = JSON.stringify(workbook.toJSON());
    const workbookClone = new GC.Spread.Sheets.Workbook();
    workbookClone.fromJSON(JSON.parse(json));
    const sheet = workbookClone.getActiveSheet();
    const columnNum = sheet.getColumnCount();
    for (let col = 0; col < columnNum; col++) {
      sheet.setColumnVisible(col, true);
    }
    useExportExcel(workbookClone, "Program Control & Review Report");
  };
  onMounted(() => {
    const { bu, ...other } = queryParams;
    const params = {
      buList: bu,
      ...other,
    };
    getFetch(params);
    // getFetch(queryParams);
  });
  onBeforeUnmount(() => {
    workbook.destroy();
  });

  return {
    getFetch,
    handlerDownload,
  };
};
export default useSpreadTableController;
