
import { defineComponent, ref, reactive, toRefs, onMounted } from "vue";
import { usetableHeight } from "@/views/System/NSTGroupMSRP/NSTGroupMSRP/utils";
import { setSearchValue, findBuVehicleList } from "@/API/approve";
import useYear from "@/hooks/Reporting/useYear";
import useQM from "@/hooks/Reporting/useQM";
import { useRoute } from "vue-router";
import useHandleCollapse from "@/hooks/Reporting/useHandleCollapse";
import useBuMakeTP, { BuOptionProps } from "@/hooks/Reporting/useBuMakeTP";
import moment from "moment";
import useEventbus from "@/hooks/claim/useEventbus";
import useSpreadTableController from "@/hooks/Reporting/views/useSpreadTableController";
import { ProgramStatusItem } from "@/views/Approve/SalesRoleApprove/ProgramHome/types";
import { getProgramStatus } from "@/API/approve";
import useFetch from "@/hooks/useFetch";
import {
  QueryParamsControllerProps,
  QueryParamsBaseControllerProps,
} from "@/views/Reporting/types";
import useFindBu from "@/hooks/Reporting/useFindBu";
const salesStatusOptions = [
  { name: "Active" },
  { name: "Inactive" },
  { name: "All" },
];
export default defineComponent({
  name: "Controller Reporting",
  setup() {
    const route = useRoute();
    const programStatus = ref<string[]>([]);
    const programSalesStatus = ref<string>("");
    const programControllingStatus = ref<string>("Active");

    const { buList, findBu } = useFindBu();
    const queryParams = reactive<QueryParamsBaseControllerProps>({
      bu: [] as string[],
      model: "",
      typeClass: "",
      make: ["CBU"],
    });

    // program status
    const { data: programStatusOptions } = useFetch<ProgramStatusItem[]>(
      {},
      [],
      getProgramStatus
    );
    // year
    const { year, openYear, handlerOpenChange, handlerPanelChange } = useYear();
    // Q/M
    const { SHOW_PARENT, qmOptions, qm: month, getDefaultQm } = useQM();

    const { handleCollapse, isOpen } = useHandleCollapse();

    // 一定要放在后面
    queryParams.bu = [findBu()];
    const {
      handleFocus,
      makeOptions,
      brandOptions,
      typeClassOptions,
      modelOptions,
      resetObj,
    } = useBuMakeTP<QueryParamsBaseControllerProps>(queryParams as any);

    // 如果跳转过来的
    if (Object.keys(route.query).length > 0) {
      Object.assign(queryParams, route.query);
      queryParams.bu = (route.query.bu as string).split("/");
      year.value = route.query.year as string;
      month.value = (route.query.monthList as string).split(",");
      if (route.query.model === "ALL") {
        queryParams.model = "";
      }
    }
    // BU的默认值为MB
    const { getFetch, handlerDownload } = useSpreadTableController(
      Object.assign({}, queryParams, {
        year: year.value,
        monthList: month.value,
        programStatus: programStatus.value,
        programSalesStatus: programSalesStatus.value,
        programControllingStatus: programControllingStatus.value,
        nstGroupCode: queryParams.model,
      })
    );

    const { tableHeight } = usetableHeight("#ssHost");

    const handlerSearchClick = () => {
      const queryParam = Object.assign({}, queryParams, {
        year: year.value,
        monthList: month.value,
        programStatus: programStatus.value,
        programSalesStatus: programSalesStatus.value,
        programControllingStatus: programControllingStatus.value,
        nstGroupCode: queryParams.model,
      });
      const { bu, ...other } = queryParam;
      const params = {
        buList: bu,
        ...other,
      };
      getFetch(params);
    };
    const handlerReset = (): void => {
      Object.assign(queryParams, resetObj);
      year.value = moment().add("month", 1).format("YYYY");
      month.value = [getDefaultQm()];
      programStatus.value = [];
      programSalesStatus.value = "";
      programControllingStatus.value = "";
    };

    return {
      modelOptions,
      typeClassOptions,
      makeOptions,
      handleFocus,
      ...toRefs(queryParams),
      queryParams,
      year,
      openYear,
      handlerOpenChange,
      handlerPanelChange,
      month,
      qmOptions,
      handleCollapse,
      isOpen,
      handlerSearchClick,
      handlerReset,
      programStatusOptions,
      SHOW_PARENT,
      handlerDownload,
      salesStatusOptions,
      tableHeight,
      programStatus,
      programSalesStatus,
      programControllingStatus,
      buList,
    };
  },
});
